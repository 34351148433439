import { IEnvironment } from "./environment.interface";

const protocol = window.location.protocol;
const host = window.location.host;

const appHost = `${host}`;
const apiHost = `${host}`;
const appUrl = `${protocol}//${host}`;
const apiUrl = `${protocol}//${host}/api`;
const webHookUrl = 'https://api.aspfs.io/media-gallery-api/govideo';
const errorLogApiUrl = `${apiUrl}/client-log`;
const timeoutInMinutes = 15;

export const environment: IEnvironment = {
  production: true,
  enableDebugTools: false,
  logLevel: 'error',
  apiHost: apiHost,
  apiUrl: apiUrl,
  appUrl: appUrl,
  webHookUrl: webHookUrl,
  errorLogApiUrl: errorLogApiUrl,
  timeoutInMinutes: timeoutInMinutes,
  appHost: appHost,
};
