import { Injectable } from '@angular/core';
import { S3Object } from '../interfaces/common.interface';
import { EnvironmentService } from './environment.service';
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: 'root'
})
export class S3UploadsService {
  private storeKey: string = 'QUEUED_PHOTOS';
  private uploadInProgress: boolean = false;

  constructor(private environmentService: EnvironmentService, private logger: NGXLogger) { 
  
  }

  async getUploadUrl(sessionId: string = 'test-session-000', mimeType: string = 'image/png'): Promise<S3Object | string> {
    try {
      const response = await fetch(`${this.environmentService.apiUrl}/sign-upload-url`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId: sessionId, mimeType: mimeType, hostFqdn: this.environmentService.apiHost }),
      }); 
      const { uploadUrl, objectKey } = await response.json();
      const dwnldRes = await fetch(`${this.environmentService.apiUrl}/sign-download-url`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ objectKey: objectKey }),
      });
      const { downloadUrl } = await dwnldRes.json();
      return {
        uploadUrl: uploadUrl,
        downloadUrl: downloadUrl,
        objectKey: objectKey
      }
    }
    catch (e) {
      console.error(`Could not get upload URL.\n${e}`);
      return '';
    }
  }

  public async upload(file: Blob, sessionId: string, roomSid: string, coordinates: GeolocationCoordinates): Promise<S3Object> {
    this.logger.info(`Uploading photo for session ${sessionId} to S3...`, { sessionId });
    const headers = new Headers();
    headers.append('Content-Type', file.type);
    headers.append('x-amz-acl', 'private');
    const requestOptions: RequestInit = {
      method: 'put',
      headers: headers,
      body: file,
      mode: 'cors'
    };
    return new Promise((resolve, reject) => {
      const url = this.getUploadUrl(sessionId, file.type).then(
        (res) => {
          const s3Object = <S3Object> res;
          if (s3Object) {
            fetch(s3Object.uploadUrl, requestOptions).then(
              () => {
                this.logger.info(`Photo ${s3Object.objectKey} successfully uplodaed to S3.`, { sessionId });
                fetch(`${this.environmentService.apiUrl}/media`, {
                  method: 'post', 
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ 
                    sessionId: sessionId, 
                    key: s3Object.objectKey,
                    roomSid: roomSid,
                    coordinates: (coordinates && coordinates.latitude) ? 
                    [coordinates.latitude, coordinates.longitude, coordinates.accuracy] : null
                  })
                });
                resolve(s3Object);
              }, 
              () =>  {
                this.logger.error(`Unable to upload photo ${s3Object.objectKey} to S3 due to an exception.`, { sessionId });
                reject('upload failed')
              });
          } else {
            reject('response is not of type S3Object');
          }
        }, 
        (err) => {reject(false)});
    });
  }

}
