import { Component, Input } from '@angular/core';
import { validateEmail } from 'app/interfaces/app-constants';
import { Notify } from 'notiflix';
import { EnvironmentService } from '../services/environment.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {
  @Input() sessionId: string;
  @Input() roomSid: string;
  @Input() emailSubject : string;
  @Input() coordinatorEmail : string;
  @Input() description : string;

  descriptionLabel: string;
  textarea: HTMLTextAreaElement;
  selfComponent : HTMLDivElement;
  maxLength : number = 1024;

  constructor(private environmentService: EnvironmentService) {}

  ngOnInit(): void {
    this.selfComponent = document.querySelector('.feedback-component');
    this.descriptionLabel = "Describe your issue"
    this.textarea = <HTMLTextAreaElement> document.getElementById('description-container');
  }

  submitFeedback() {
    fetch(`${this.environmentService.apiUrl}/coordinator-feedback`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sessionId: this.sessionId,
        roomSid: this.roomSid,
        coordinatorEmail: this.coordinatorEmail,
        emailSubject: this.emailSubject,
        description: this.description
      })
    }).then( res => {
      if (res.status > 299) {
        Notify.warning('Unable to submit feedback. Please try again!', { position: 'center-top' });
      } else {
        Notify.success('We have received your feedback', { position: 'center-top' });
      }
    })
    this.selfComponent.style.display = "none";
  }

  cancel() {
    this.selfComponent.style.display = "none";
  }

  updateDescriptionLabel() {
    console.log("change detected")
    if (this.textarea) {
      const curLength = this.textarea.value.length;
      if (curLength < 3) {
        this.descriptionLabel = 'Please describe your issue in detail'
      } else {
        const remaining = this.maxLength - curLength;
        this.descriptionLabel = `${remaining} characters remaining`
      }
      
    }
  }

  invalidModalState() {
    return !validateEmail(this.coordinatorEmail) 
          || !this.description || this.description.length < 2 
          || !this.emailSubject || this.emailSubject.length > 40
  }
}
