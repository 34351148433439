export enum DataCommand {
    CAPTURE_IMAGE = 'capture image',
    CAPTURE_IMAGE_ACK = 'capture image ack',
    CAPTURE_IMAGE_COMPLETE = 'capture image complete',
    IMAGE_UPLOAD_COMPLETE = 'image upload complete',
    // VIDEO_SOURCES_REQUESTED = 'video sources requested',
    // VIDEO_SOURCES_PROVIDED = 'video sources provided',
    CAMERA_SWITCH_REQUESTED = 'camera switch requested',
    CAMERA_SWITCH_COMPLETED = 'camera switch completed',
    SEND_CHAT_MESSAGE = 'send chat message',
    RECEIVE_CHAT_MESSAGE = 'receive chat message',
    VIDEO_RECORDING_STOPPED = 'video recording stopped',
    INSPECTOR_LOCATION_DATA = 'inspector location data',

    DEFAULT = CAPTURE_IMAGE
}