import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISessionSummary } from 'app/interfaces/session-summary.interface';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.css']
})
export class EndSessionComponent implements OnInit {

  roomSid: string;
  sessionId: string;
  participantId: string;
  isCoordinator: boolean;
  sessionSummary: ISessionSummary;
  constructor(private route: ActivatedRoute, private sessionService: SessionService) {
   }

  ngOnInit(): void {
    this.roomSid = this.route.snapshot.paramMap.get('roomSid');
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');
    this.participantId = this.route.snapshot.paramMap.get('participantId');
    this.isCoordinator = this.participantId === 'coordinator';
    if (this.isCoordinator && this.sessionId !== '0') {
      this.sessionService.getSessionSummary(this.sessionId)
      .then((summary) => {
        this.sessionSummary = summary;
      });
    }
    document.getElementById('close-button').addEventListener('click', (event) => {
      document.body.innerHTML = '';
      setTimeout(() => {
        window.close();
      }, 350);
    });
  }
}
