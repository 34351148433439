<app-feedback
            [coordinatorEmail]="coordinatorEmail" 
            [emailSubject]="emailSubject"
>
</app-feedback>
<div class="session-page">
    <h2>Welcome to GoVideo</h2>
    <div [hidden]="linkGenerated">
        <h3>Enter details below</h3>
        <div class="form-field p-float-label">
            <input
                class="p-inputtext-sm"
                id="coordinator-email-container"
                type="email"
                [(ngModel)]="coordinatorEmail"
                [required]="true"
                [email]="true"
                pInputText
                pTooltip="To receive photos, links and videos"
                tooltipPosition="right"
            >
            <label for="coordinator-email-container">Your email</label>
            <span class="required-asteric">*</span>
        </div>

        <div class="form-field p-float-label">
            <input
                class="p-inputtext-sm"
                id="inspector-email-container"
                type="email"
                [(ngModel)]="inspectorEmail"
                [email]="true"
                pInputText
                pTooltip="To send the session link"
                tooltipPosition="right"
            >
            <label for="inspector-email-container">Device holder's email</label>
        </div>

        <div class="form-field p-float-label">
            <p-inputMask
                class="p-inputtext-sm"
                styleClass="inspector-phone-number-style"
                id="inspector-phone-number"
                mask="(999) 999-9999"
                type="tel"
                autocomplete="tel-national"
                [(ngModel)]="inspectorPhoneNumber"
                [required]="true"
                (click)="resetCaretPosition()"
                [autoClear]="false"
                pTooltip="To send the session link"
                tooltipPosition="right"
            ></p-inputMask>
            <label for="inspector-phone-number">Device holder's phone number</label>
            <span class="required-asteric">*</span>
        </div>

        <div class="form-field p-float-label">
            <input
                class="p-inputtext-sm"
                id="email-subject-container"
                [(ngModel)]="emailSubject"
                pInputText
                pTooltip="For your own reference when looking for this session's email"
                tooltipPosition="right"
                maxlength="40"
            >
            <label for="email-subject-container">Subject/Reference</label>
        </div>
        <div id="controls">
            <button
            class="p-button-sm"
            id="create-session"
            type="button"
            label="Create and send GoVideo Session"
            pButton
            [hidden]="linkGenerated"
            [disabled]="invalidFormState()"
        ></button>
        <button
            class="p-button-sm"
            id="create-session"
            type="button"
            label="Need Help?"
            pButton
            [hidden]="linkGenerated"
            (click)="getHelp()"
        ></button>
        </div>
    </div>

    <div *ngIf="linkGenerated">
        <div class="inspector-message" *ngIf="inspectorEmail || inspectorPhoneNumber">
            <span class="pi pi-check" style="font-size: 1rem; color: 'green'; margin-right: 0.5rem;"></span>{{generateInspectorMessage()}}
        </div>
        <div id="coordinator-link">
            <p><strong>Host Link:</strong> <a href={{coordinatorLink}} target="_blank">{{coordinatorLink}}</a></p>
        </div>
        <div id="inspector-link">
            <p><strong>Device Holder's Link:</strong> <a href={{inspectorLink}} target="_blank">{{inspectorLink}}</a></p>
        </div>
    </div>
</div>
