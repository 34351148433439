<h2> End of session</h2>
<div id="thank-you">
    <!-- <p>You can use Twilio console to access recordings and composition for Room SID: {{roomSid}}</p> -->
    <img src = "assets/icons/thank-you.jpeg" class="center">
</div>
<div *ngIf="isCoordinator">
    <p>Thank you for using GoVideo.</p>
    <div *ngIf="sessionSummary">
        <p>Here is a summary of your session:</p>
        <ul>
            <li>Photos captured: <b>{{sessionSummary.media.length}}</b></li>
            <li *ngIf="sessionSummary.hasRecording">Video composition Created: <b>Yes</b></li>
            <li *ngIf="!sessionSummary.hasRecording">Video composition Created: <b>No</b></li>
        </ul>
        <p *ngIf="sessionSummary.contact">In a moment, you will receive an email at <b>{{sessionSummary.contact.coordinatorEmail.toUpperCase()}}</b> with a link for downloading
            your photos (in a zip archive). Another separate email will be sent with a link to your video when processing has been completed.</p>
    </div>
</div>
<hr />
<div>
    You can leave this page now: 
    <button id="close-button">Leave Session</button>
</div>
