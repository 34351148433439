<app-feedback *ngIf="coordinatorEmail"
            [coordinatorEmail]="coordinatorEmail" 
            [emailSubject]="emailSubject"
            [sessionId]="sessionId"
            [roomSid]="roomSid"
>
</app-feedback>
<div class="coordinator-session" [hidden]="isLoading">
    <div class="primary-pane">
        <div *ngIf="coordinatorEmail" class="subject">
            <button id="need-help" (click)="handleHelpButtonClick();">Need Help?</button>
            <h3 contenteditable="true" (click)="handleSubjectClick();">{{emailSubject}}</h3>
            <img id="subject-icon" src={{titleIconSrc}} (click)="handleIconClick();">
        </div>
        <app-inspector-video-container></app-inspector-video-container>
    </div>
    <div class="secondary-pane">
        <!-- Debugging controls-->
        <button id="stats-monitor">Toggle Connection Stats</button>
        <app-coordinator-video-container></app-coordinator-video-container>
        <!-- controls to capture photo, record and end session -->
        <button id="capture-image" disabled="true">Take photo</button>
        <button id="record-button" class="start-recording" disabled="true">Start Recording</button>
        <button id="end-session" [hidden]="!isInspectorConnected" disabled="true">End Session</button>
        <button id="cancel-session" [hidden]="isInspectorConnected">Cancel Session</button>
        <!-- controls to change inspector's video source -->
        <select id="video-devices" disabled="true">
            <option value="environment">Back Camera</option>
            <option value="user">Front Camera</option>
        </select>
        <!--- control to display inspector's last known location -->
        <div id="inspector-location" class="{{inspectorLocationClass}}" *ngIf="inspectorLocationError || inspectorLocationObtained">
            <span><b>Inspector Location</b></span>
            <div id="inspector-coordinates" *ngIf="inspectorLocationObtained">
                <a target="_blank" href="{{locationHref}}"><img alt="view location" height="160px" src="{{locationSrc}}"/></a>
            </div>
            <span *ngIf="inspectorLocationError">{{inspectorLocationErrorMsg}}</span>
        </div>
        
    </div>
    <div class="tertiary-pane">
        <button id="video-toggle">Disable Coordinator Video</button>
        <app-chat-container></app-chat-container>
    </div>
</div>
<div id="thumbnail-container"></div>