import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { NGXLogger } from 'ngx-logger';
import { PhotoUploadSchema } from './interfaces/app-constants';
import { StoredPhoto } from './interfaces/common.interface';
import { UploadedPhoto } from 'app/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private dbService: NgxIndexedDBService, private logger: NGXLogger) { }

  public add(sessionId: string, imageId: string, data: Blob, thumbnail: string): void {
    try { 
      this.dbService.add(PhotoUploadSchema, {
        imageId: imageId,
        sessionId: sessionId,
        data: data,
        thumbnail: thumbnail,
        uploadCompleted: false,
        createdAt: new Date()
      }).subscribe(value => {
        this.logger.trace('store.persist() success.', {sessionId, imageId});
        console.log(value);
      })
    } catch(e) {
      this.logger.error('Error in store.persist()', {sessionId, error: e});
    }
  }

  public getStoredPhotos(sessionId: string): Promise<StoredPhoto[]> {
    var existing: StoredPhoto[] = [];
    return new Promise((resolve, reject) => {
      try {
        this.dbService.getAllByIndex(PhotoUploadSchema, 'sessionId', IDBKeyRange.only(sessionId)).subscribe(result => {
          result.forEach(photo => {
            existing.push({
              sessionId: photo['sessionId'],
              imageId: photo['imageId'],
              data: photo['data'],
              thumbnail: photo['thumbnail'],
              uploadCompleted: photo['uploadCompleted'],
              createdTime: photo['createdTime']
            });
          })
          if (existing.length == 0) {
            // cleanup store
            this.dbService.clear(PhotoUploadSchema);
          }
          resolve(existing);
        })
      } catch(e) {
        this.logger.error('Error in store.getStoredPhotos()', {sessionId, error: e});
        reject();
      }
    });
  }

  public markComplete(imageId: string): void {
    try {
      this.dbService.getByIndex(PhotoUploadSchema, 'imageId', imageId).subscribe(result => {
        this.dbService.update(PhotoUploadSchema, {
          id: result['id'],
          sessionId: result['sessionId'],
          imageId: result['imageId'],
          data: result['data'],
          thumbnail: result['thumbnail'],
          createdTime: result['createdTime'],
          uploadCompleted: true
        }).subscribe(updated => {
          
        })
      })
    } catch (e) {
      this.logger.error('Error in store.markComplete()', {imageId, error: e});
    }
  }

  public remove(sessionId: string): void {
    try {
      this.dbService.deleteObjectStore(PhotoUploadSchema);
      this.logger.trace('store.remove() success', {sessionId})
    } catch(e) {
      this.logger.error('Error in store.remove()', { error: e });
    }
  }
}
