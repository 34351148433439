import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InspectorComponent } from './inspector/inspector.component';
import { CoordinatorComponent } from './coordinator/coordinator.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { StartSessionComponent } from './start-session/start-session.component';

const routes: Routes = [
  {path: '', component: HomeComponent, title: 'GoVideo'},
  {path: 'inspector/:sessionId', component: InspectorComponent, title: 'Field Inspector'},
  {path: 'coordinator/:sessionId', component: CoordinatorComponent, title: 'Coordinator'},
  {path: 'end-session/:participantId/:roomSid/:sessionId', component: EndSessionComponent, title: 'Session Ended'},
  {path: 'start-session/:token/:sessionId', component: StartSessionComponent, title: 'GoVideo Starting'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
