<div id="fullscreen-container">
    <div class="inspector-session" [hidden]="isLoading">
        <div class="primary-pane">
            <app-inspector-video-container></app-inspector-video-container>
            <!-- controls to change audio source -->
        </div>
        <div class="secondary-pane">
            <button id="fullscreen-button" [hidden]="isFullscreen">Fullscreen Mode</button>
            <app-coordinator-video-container></app-coordinator-video-container>
            <app-chat-container></app-chat-container>
        </div>
        <!-- controls to place snapshots -->
        <div id="image-container"></div>
    </div>
</div>