import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { CoordinatorComponent } from './coordinator/coordinator.component';
import { InspectorComponent } from './inspector/inspector.component';
import { InspectorVideoContainerComponent } from './inspector-video-container/inspector-video-container.component';
import { CoordinatorVideoContainerComponent } from './coordinator-video-container/coordinator-video-container.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { ChatContainerComponent } from './chat-container/chat-container.component';
import { PhotoUploadSchema } from './interfaces/app-constants';
import { HomeComponent } from './home/home.component';
import { ErrorHandlerService } from '@services/error-handling.service';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { FeedbackComponent } from './feedback/feedback.component';
import { CommonModule } from '@angular/common';

const dbConfig: DBConfig = {
  name: 'GV2',
  version: 1,
  objectStoresMeta: [{
    store: PhotoUploadSchema,
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'imageId', keypath: 'imageId', options: {unique: false} },
      { name: 'sessionId', keypath: 'sessionId', options: {unique: false} },
      { name: 'data', keypath: 'data', options: {unique: false} },
      { name: 'thumbnail', keypath: 'thumbnail', options: {unique: false} },
      { name: 'uploadCompleted', keypath: 'uploadCompleted', options: {unique: false} },
      { name: 'createdAt', keypath: 'createdAt', options: {unique: false} }
    ]
  }]
}

@NgModule({
  declarations: [
    AppComponent,
    CoordinatorComponent,
    InspectorComponent,
    InspectorVideoContainerComponent,
    CoordinatorVideoContainerComponent,
    EndSessionComponent,
    ChatContainerComponent,
    HomeComponent,
    FeedbackComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: environment.errorLogApiUrl,
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.TRACE
    }),
    FormsModule,
    InputMaskModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    CommonModule,
  ],
  providers: [{provide: ErrorHandler, useClass: ErrorHandlerService}],
  bootstrap: [AppComponent]
})
export class AppModule { }
