import { ParticipantSide } from '../enums/participant-side.enum';
function delayInMs(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

function iPhoneCheck() : boolean {
    if (navigator.userAgent && navigator.userAgent.includes('iPhone')) {
        return true;
    }
    return false;
}

function appendChat(side: ParticipantSide, text: String) {
    const chatLog = <HTMLDivElement> document.getElementById('chat-log');
    const entry = document.createElement('p');
    entry.className = side;
    entry.innerHTML = '<strong>' + side + ':</strong>' + ' ' + text;
    chatLog.appendChild(entry);
    chatLog.scrollTop = chatLog.scrollHeight;
}

function idealHeight() : number {
    return iPhoneCheck() ? 1080 : 1440;
}

function idealWidth() : number {
    return iPhoneCheck() ? 1440 : 1920;
}

function generateId(length: number): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function InspectorTrackConstraints(facingMode: string): MediaTrackConstraints {
    return {
        width: { ideal: idealWidth() },
        height: { ideal: idealHeight() },
        facingMode: { ideal: facingMode },
        aspectRatio: {exact: 4/3 }   
    };
}

function InspectorStreamConstraints(facingMode: string): MediaStreamConstraints {
    return {
        audio: false,
        video: InspectorTrackConstraints(facingMode)
    }
}

function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
}

function convertBase64ToBlob(base64Image: string): Blob {
    // Split into two parts
    const parts = base64Image.split(';base64,');
  
    // Hold the content type
    const imageType = parts[0].split(':')[1];
  
    // Decode Base64 string
    const decodedData = window.atob(parts[1]);
  
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
  
    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
  
    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
}

const CoordinatorStreamConstraints: MediaStreamConstraints = {
    audio: false,
    video: {
        width: { ideal: 480 },
        height: { ideal: 360 },
        aspectRatio: {exact: 4/3 }   
    }
}

const EditIconSrc: string = "../assets/icons/edit-icon.svg";
const SaveIconSrc: string = "../assets/icons/save-icon.svg";

const Headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "skip",
    "User-Agent": "GoVideo-Twilio",
  };

const PhotoUploadSchema = 'photo_upload_schema';

const RearFacing = 'environment';
const FrontFacing = 'user';

export {
    CoordinatorStreamConstraints, Headers, PhotoUploadSchema, RearFacing, FrontFacing, EditIconSrc, SaveIconSrc, convertBase64ToBlob,
    InspectorStreamConstraints, InspectorTrackConstraints, delayInMs, generateId, appendChat, idealWidth, idealHeight, iPhoneCheck, validateEmail
}