import { Directive, Injectable } from '@angular/core';
import { delayInMs } from '../interfaces/app-constants';
import { ImageSettingsInterface, HiResImageSettings, ThumbnailImageSettings } from '../interfaces/image-settings.interface';
import { ParticipantSide } from '../enums/participant-side.enum';
import { NGXLogger } from 'ngx-logger';
@Injectable({
  providedIn: 'root'
})
export class SnapshotService {

  storage: Array<Blob>;
  constructor(private logger: NGXLogger) {

  }

  public async captureScreenshot(video: HTMLVideoElement, imagesDiv: HTMLDivElement, imageId: string): Promise<Blob | void> {
    try {
      const blob = await this.createImageFromCanvas(video, imagesDiv, imageId, HiResImageSettings);
      if(blob) {
        return blob;
      }
    } catch (e) {
      this.logger.error(`Exception caught in captureScreenshot()`, { errorMessage: e.message, error: e });
    }
  }

  public captureThumbnail(imagesDiv: HTMLDivElement, imageId: string, base64: string) {
    this.appendImage(imagesDiv, imageId, base64, ThumbnailImageSettings.height);
  }

  public createImageFromCanvas(video: HTMLVideoElement, 
                              imagesDiv: HTMLDivElement,
                              imageId: string, 
                              settings: ImageSettingsInterface): Promise<Blob|void> {
    
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const contentType = 'image/jpeg';
      
      // adjust dimenstions based on orientation
      let height = (video.videoWidth >= video.videoHeight) ? settings.height : settings.width;
      let width = (video.videoWidth >= video.videoHeight) ? settings.width : settings.height;
  
      // preserve aspect ratio and avoid upscaling / supersampling
      height = Math.min(height, video.videoHeight);
      width = Math.min(width, video.videoWidth);
  
      canvas.height = height;
      canvas.width = width;
      const canvasStats = {
        'canvasHeight': canvas.height,
        'canvasWidth': canvas.width
      };
      console.log(canvasStats);
      ctx.drawImage(video, 0, 0, width, height);
      return new Promise((resolve, reject) => {
        this.logger.trace(`Converting canvas to blob in createImageFromCanvas() / return new Promise().`);
        canvas.toBlob((blob) => {
          if (blob === null) {
            console.log("Failed to convert canvas to blob");
              reject();
          }
          const imageUrl = URL.createObjectURL(blob);
          this.appendImage(imagesDiv, imageId, imageUrl, settings.thumbnailHeight);
          this.logger.trace(`Successfully converted canvas to blob in createImageFromCanvas() / resolve(blob).`)
          resolve(blob);
        }, contentType, settings.quality);
      });
    } catch (e) { 
      this.logger.error(`Exception caught in createImageFromCanvas()`, { errorMessage: e.message, error: e });
      return Promise.reject();
    }
  }

  public appendImage(imagesDiv: HTMLDivElement, imageId: string, imgSrc: string, height: number) {
    let imgElem = new Image();
    imgElem.src = imgSrc;
    imgElem.height = height;
    imgElem.className = 'captured';
    const anchor = document.createElement('a');
    anchor.id = imageId;
    anchor.className = 'image-box';
    anchor.appendChild(imgElem);
    anchor.target = '_blank';
    anchor.style.pointerEvents = 'none';

    let successImg = new Image();
    successImg.src = 'assets/icons/upload-complete-192x192.png'
    successImg.height = height / 2;
    successImg.className = 'overlay';
    successImg.hidden = true;

    anchor.appendChild(successImg);
    imagesDiv.appendChild(anchor);
    imagesDiv.scrollLeft = imagesDiv.scrollWidth;
    imagesDiv.scrollTop = imagesDiv.scrollHeight;
  }

  public markUploadComplete(imageId: string, url: string, side: ParticipantSide) {
    const anchor = <HTMLAnchorElement> document.getElementById(imageId);
    if (!anchor) return;
    if (side === ParticipantSide.COORDINATOR) {
      anchor.href = url;
      anchor.style.pointerEvents = '';
    }
  
    const capturedImage = <HTMLImageElement> document.querySelector('#'+imageId+' .captured');
    const overlayImage = <HTMLImageElement> document.querySelector('#'+imageId+' .overlay');
    if (capturedImage) {
      if (side === ParticipantSide.INSPECTOR) {
        URL.revokeObjectURL(capturedImage.src); // revoke cached URL to free up browser memory
      }
      capturedImage.src = url;
    }
    if (overlayImage) {
      overlayImage.hidden = false;
    }
  }
}
