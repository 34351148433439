import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoConnectionManagerService } from '../services/video-connection-manager.service';
import { ParticipantSide } from '../enums/participant-side.enum';
import { SnapshotService } from '../services/snapshot.service';
import { DataCommand } from '../enums/data-command.enum';
import { EnvironmentService } from '../services/environment.service';
import { appendChat, generateId, InspectorStreamConstraints, RearFacing, iPhoneCheck, delayInMs} from '../interfaces/app-constants';
import { S3UploadsService } from '../services/s3-uploads.service';
import { UploadedPhoto } from 'app/interfaces/common.interface';
import { fromBlob } from 'image-resize-compress';
import { NGXLogger } from 'ngx-logger';
import { HiResImageSettings, ThumbnailImageSettings } from 'app/interfaces/image-settings.interface';
import { Block, Confirm, Loading, Report } from 'notiflix';
import { StoreService } from '../store.service';
import { GeolocationService } from '../services/geolocation.service';
@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css']
})
export class InspectorComponent implements OnInit, OnDestroy {
  isLoading = true;
  isFullscreen = false;
  recordingCompleted = false;
  sessionId: string;
  currentStream: MediaStream;
  facingMode: string;
  chatButton: HTMLButtonElement;
  constructor(private environmentService: EnvironmentService,
              private elementRef: ElementRef,
              private route: ActivatedRoute, 
              private router: Router,
              private videoConnectionManagerService: VideoConnectionManagerService, 
              private snapshotService: SnapshotService,
              private s3UploadsService: S3UploadsService,
              private storeService: StoreService,
              private locationService: GeolocationService,
              private logger: NGXLogger) { }
  
  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit(): void {
    let that = this
    Confirm.show('Kindly ensure', 
      '1. Your device has sufficient battery. &nbsp;&nbsp; 2. No other audio or video application is running in the background.', 
      'Proceed', 'Exit',
      () => {
        that.onProceed()
      },
      () => {
        window.close()
      }
    )
  }

  onProceed() {
    Loading.pulse('Connecting to session...');
    let that = this;
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');
    this.locationService.logUserAgent(this.sessionId);
    this.logger.info('Device and Session Info: ', {sessionId: this.sessionId, userAgent: navigator.userAgent});
    this.chatButton = <HTMLButtonElement>document.getElementById('send-message');

    this.videoConnectionManagerService.coordinatorCommand$.subscribe(message => {
      switch (message.command) {
        case DataCommand.CAPTURE_IMAGE:
          that.handleImageCapture();
          break;
        case DataCommand.CAMERA_SWITCH_REQUESTED:
          that.handleCameraSwitch(message.data?.facingMode);
          break;
        case DataCommand.SEND_CHAT_MESSAGE:
          appendChat(message.data.side, message.data.content);
          break;
        case DataCommand.VIDEO_RECORDING_STOPPED:
          that.recordingCompleted = true;
          break;
        default:
          console.log('Not Implemented: ' + message.command);
      }
    });

    this.videoConnectionManagerService.sessionCompleted$.subscribe(message => {
      if (message && that.recordingCompleted) {
        that.endSessionCleanup();
      }
    });
    this.videoConnectionManagerService.sessionTimeout$.subscribe(message => {
      if (message) {
        that.endSessionCleanup();
      }
    })
    this.chatButton.addEventListener('click', (event) => {
      event.preventDefault();
      that.videoConnectionManagerService.sendChat(ParticipantSide.INSPECTOR, this.chatButton);
    });
    document.getElementById('active-message').addEventListener('keypress', (event) => {
      if (event.keyCode === 13) {
        that.videoConnectionManagerService.sendChat(ParticipantSide.INSPECTOR, this.chatButton);
      }
    });

    this.recoverStoredPhotos();

    this.facingMode = RearFacing;
    this.initStream().then(() => {
      return this.videoConnectionManagerService.joinSession(this.sessionId, false, ParticipantSide.INSPECTOR, this.currentStream);
    })
      .then(token => {
        if (token) {
          this.isLoading = false;
        }
        Loading.remove();
        that.videoConnectionManagerService.timeoutWithoutParticipant(that.sessionId, ParticipantSide.COORDINATOR);
        that.orientationChecks();
        const roomSid = that.videoConnectionManagerService.getRoomInfo().sid;
        that.locationService.handleLocationLogging(that.sessionId, roomSid, that.videoConnectionManagerService);
      });
  }

  orientationChecks(): void {
    const fullscreenButton = document.getElementById('fullscreen-button');
    const fullscreenContainer = document.getElementById('fullscreen-container');
    const appDiv = document.getElementById('fullscreen-container');
    fullscreenContainer.addEventListener('fullscreenchange', (event) => {
      // toggle fullscreen flag
      this.isFullscreen = (this.isFullscreen) ? false : true;
    });
    if (iPhoneCheck()) {
      fullscreenButton.hidden = true;
      if (window.orientation == 0) {
        Block.arrows('.primary-pane', 'Kindly rotate your device');
      }
      window.addEventListener('orientationchange', () => {
        if (window.orientation == 0) {
          Block.arrows('.primary-pane', 'Kindly rotate your device');
        } if (window.orientation == 90 || window.orientation == -90) {
          Block.remove('.primary-pane');
        }
      }, true);
    }
    fullscreenButton.addEventListener('click', (event) => {
      event.preventDefault();
      try {
        if (appDiv.requestFullscreen) {
          appDiv.requestFullscreen().then(() => {
            fullscreenButton.hidden = true;
            if (screen.orientation && screen.orientation.hasOwnProperty('lock')) {
              try {
                (screen.orientation as any).lock('landscape-primary');
              } catch (e) {
                this.logger.error('unable to switch to landscape orientation', { sessionId: this.sessionId, error: e });
              }
            }
          });
        } else {
          this.logger.warn('requestFullscreen() unavailable', { sessionId: this.sessionId });
        }
      } catch (e) {
        this.logger.error('Unable to switch to fullscreen mode', { sessionId: this.sessionId, error: e });
      }
    })
  }

  handleImageCapture(): void {
    console.log('Take Picture clicked');
    const imageContainer = <HTMLDivElement> document.getElementById('image-container');
    const inspectorVideo = <HTMLVideoElement> document.querySelector('.inspector-video video');
    if (inspectorVideo) {
      const imageId = 'shot-' + generateId(6);
      this.videoConnectionManagerService.sendDataCommand({
        command: DataCommand.CAPTURE_IMAGE_ACK,
        data: {
          'imageId': imageId,
          'base64': null
        }
      });
      this.snapshotService.captureScreenshot(inspectorVideo, imageContainer, imageId).then((blob) => {
        const blobData = <Blob> blob;
        if (blobData) {
          const height = (inspectorVideo.videoWidth >= inspectorVideo.videoHeight) ? ThumbnailImageSettings.height : ThumbnailImageSettings.width;
          const width = (inspectorVideo.videoWidth >= inspectorVideo.videoHeight) ? ThumbnailImageSettings.width : ThumbnailImageSettings.height;
          fromBlob(blobData, ThumbnailImageSettings.quality, width, height, 'webp').then(thumbnail => {
            this.convertBlobToBase64(thumbnail).then(base64 => {
              try {
                this.storeService.add(this.sessionId, imageId, blobData, base64);
              } catch(e) {
                this.logger.error('Error persisting in store', {sessionId: this.sessionId});
              }
              this.videoConnectionManagerService.sendDataCommand({
                command: DataCommand.CAPTURE_IMAGE_COMPLETE,
                data: {
                  'imageId': imageId,
                  'base64': base64
                }
              });
              this.uploadToS3(imageId, blobData);
            });
          });
        }
      });
    } else {
      console.warn('no video element found. Skipping image capture');
    }
  }

  async uploadToS3(imageId: string, data: Blob) {
    const roomSid = this.videoConnectionManagerService.getRoomInfo().sid;
    const coordinates = this.locationService.getCachedPosition();
    
    this.s3UploadsService.upload(data, this.sessionId, roomSid, coordinates).then((s3Object) => {
      const uploaded: UploadedPhoto = {
        imageId: imageId,
        objectKey: s3Object.objectKey,
        url: s3Object.downloadUrl
      }
      this.handleCompletedUpload(uploaded);
      try {
        this.storeService.markComplete(imageId);
      } catch(e) {
        this.logger.error('Error removing from store', {sessionId: this.sessionId});
      }
    })
  }

  handleCompletedUpload(photo: UploadedPhoto) {
    this.snapshotService.markUploadComplete(photo.imageId, photo.url, ParticipantSide.INSPECTOR);
    this.videoConnectionManagerService.sendDataCommand({
      command: DataCommand.IMAGE_UPLOAD_COMPLETE,
      data: photo
    });
  }

  async initStream() {
    try {
      this.currentStream = await navigator.mediaDevices.getUserMedia(InspectorStreamConstraints(RearFacing));
    } catch (e) {
      Report.failure('Unable to obtain camera permissions',
      'Go Video requires access to camera and microphone to conduct an inspection. Kindly refresh your page and allow the permissions',
      'Okay')
      this.logger.error('Unable to obtain video stream', {sessionId: this.sessionId, error: e});
      fetch(`${this.environmentService.apiUrl}/event`, {
        method: 'post', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          sessionId: this.sessionId, 
          type: 910, // CAMERA_PERMISSION_DENIED
          message: `camera access denied by inspector`
        })
      });
      Loading.remove();
    }
  }

  async handleCameraSwitch(facingMode: string) {
    this.logger.trace('handleCameraSwitch', {facingMode});
    if (facingMode) {
      if (facingMode !== this.facingMode) {
        this.videoConnectionManagerService.stopTracks();
        this.stopTracks();
        await this.updateStream(facingMode);
        this.videoConnectionManagerService.publishVideoTrack(this.currentStream, ParticipantSide.INSPECTOR);
      } else {
        console.log('same facing mode. skipping');
      }
      this.logger.trace('Sending video switch data command back to coordinator.', {sessionId: this.sessionId});
      this.videoConnectionManagerService.sendDataCommand({
        command: DataCommand.CAMERA_SWITCH_COMPLETED,
        data: {facingMode: facingMode}
      });
    }
    else {
      console.warn('facing mode not specified');
    }
    this.logger.trace('Exiting handleCameraSwitch call.');
  }

  async convertBlobToBase64(blob: Blob): Promise<string>{
    try {
      const base64 = <string> await this.blobToBase64(blob);
      return base64;
    } catch(e) {
      this.logger.error('error converting to base64', {sessionId: this.sessionId, error: e});
      return Promise.reject();
    }
  }
  
  blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async updateStream(facingMode: string) : Promise<void> {
    if (facingMode && facingMode !== this.facingMode) {
      this.logger.trace('Switching camera stream', {sessionId: this.sessionId, facingMode});
      this.currentStream = await navigator.mediaDevices.getUserMedia(InspectorStreamConstraints(facingMode));
      this.facingMode = facingMode;
    }
  }

  stopTracks(stopAudio?: boolean) {
    const tracks = stopAudio? this.currentStream.getTracks() : this.currentStream.getVideoTracks();
    tracks.forEach(track => {
      track.stop();
    })
  }

  recoverStoredPhotos() {
    this.storeService.getStoredPhotos(this.sessionId).then(photos => {
      const imageContainer = <HTMLDivElement> document.getElementById('image-container');
      photos.forEach(photo => {
        this.snapshotService.appendImage(imageContainer, photo.imageId, photo.thumbnail, HiResImageSettings.thumbnailHeight);
        if (photo.uploadCompleted) {
          this.snapshotService.markUploadComplete(photo.imageId, photo.thumbnail, ParticipantSide.INSPECTOR);
        } else {
          this.uploadToS3(photo.imageId, photo.data);
        }
      })
    })
  }


  endSessionCleanup() {
    this.storeService.remove(this.sessionId); // cleanup local store / indexed db
    this.locationService.stopLocationLogging();
    const roomSid = this.videoConnectionManagerService.getRoomInfo().sid;
    this.videoConnectionManagerService.stopTracks(true);
    this.stopTracks(true);
    this.router.navigate(['/end-session/inspector/' + roomSid + '/' + this.sessionId]);
  }
}
