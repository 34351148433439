import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
  })
export class SessionService {
    constructor(private environmentService: EnvironmentService) {

    }

    async postEvent(sessionId: string, type: number, message: string): Promise<number|void> {
        try {
            const res: Response = await fetch(`${this.environmentService.apiUrl}/event`, {
                method: "POST",
                body: JSON.stringify({ 
                    sessionId,
                    type,
                    message
                }),
              });
              return res.status;
        } catch (e) {
            console.error(`Unable to post to event API.\n${e.message}`);
        }
    }

    async getSessionSummary(sessionId: string): Promise<any|void> {
        try {
            const res: Response = await fetch(`${this.environmentService.apiUrl}/session/summary/${sessionId}`);
            const summary = await res.json();
            return summary;
        } catch (e) {
            console.error(`Unable to get session summary.\n${e.message}`);
        }
    }

}