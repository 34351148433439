import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from "ngx-logger";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private logger: NGXLogger) {
        console.log(`Global error handler registered.`)
        super();
    }

    override async handleError(error: Error) {
        try {
            this.logger.error('Global error handler caught an unhandled exception.', error);
            console.log('Exception caught and sent to event API.');
        } catch (e) {
            console.error(`Unable to handle uncaught exception.\n${e.message}`)
        }
        // do not propogate the error to ErrorHandler
        /* finally {
            super.handleError(error);
        }*/
    }
}
