import { Component, OnInit, AfterViewInit, NgModule, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { Loading } from 'notiflix';
import { VideoConnectionManagerService } from '@services/video-connection-manager.service'; 
import { EnvironmentService } from '@services/environment.service';

@Component({
  selector: 'start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.css'],
})
export class StartSessionComponent implements OnInit, AfterViewInit {

  sessionStarting = true;
  authFailed = false;
  errorMessage = '';
  missingToken = false;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router,
    private videoConnectionManagerService: VideoConnectionManagerService,
    private environmentService: EnvironmentService, ) {
  }

  async ngOnInit(): Promise<void> {
    
    const token = this.route.snapshot.paramMap.get('token');
    const sessionId = this.route.snapshot.paramMap.get('sessionId');
    if (!token || !sessionId) {
      // show error
      this.sessionStarting = false;
      this.missingToken = true;
      return;
    }
    
    Loading.pulse('Creating Session...', {svgSize:"150px", messageFontSize:"30px"});
    
    try {
      // use http client to validate the token at api/validate-token
      const response = await fetch('/api/validate-token', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.status === 200) {
        // token is valid, create the session
        await this.videoConnectionManagerService.createSession(
          sessionId,
          '',
          '',
          '',
          '',
          '',
          '',
          this.environmentService.webHookUrl, // point to webhook url to media gallery
        )
          
        Loading.remove();
        await this.router.navigate(['/coordinator', sessionId]);
        return;
      } else {
        // token is invalid, show error
        Loading.remove();
        this.sessionStarting = false;
        this.authFailed = true;
      }
    } catch(error) {
      Loading.remove();
      this.sessionStarting = false;
      this.errorMessage = error.message;
    }
    
  }

  ngAfterViewInit(): void {
    // this.router.navigate(['/path/to/route'], { queryParams: { foo: 'bar' }, fragment: 'baz' });
  }
  
}
