import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoConnectionManagerService } from '../services/video-connection-manager.service';
import { ParticipantSide } from '../enums/participant-side.enum';
import { SnapshotService } from '../services/snapshot.service';
import { DataCommand } from '../enums/data-command.enum';
import { appendChat, convertBase64ToBlob, CoordinatorStreamConstraints, delayInMs, EditIconSrc, SaveIconSrc } from '../interfaces/app-constants';
import { NGXLogger } from 'ngx-logger';
import { Loading, Confirm, Notify, Report } from 'notiflix';
import { EnvironmentService } from '../services/environment.service';
import { S3UploadsService } from '../services/s3-uploads.service';
import { ThumbnailImageSettings } from 'app/interfaces/image-settings.interface';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.css']
})
export class CoordinatorComponent implements OnDestroy, OnInit {
  isLoading = true;
  sessionCompleted = false;
  videoEnabled = true;
  isInspectorConnected = false;
  inspectorLocationObtained = false;
  inspectorLocationError = false;
  inspectorDeniedCamera = false;
  inspectorDeniedMic = false;
  mapUploadedToS3 = false;

  sessionId: string;
  roomSid: string;
  token: string;
  stream: MediaStream;
  captureButton: HTMLButtonElement;
  recordButton: HTMLButtonElement;
  sessionButton: HTMLButtonElement;
  cancelSessionButton : HTMLButtonElement;
  statsMonitor: HTMLButtonElement;
  videoToggle: HTMLButtonElement;
  chatButton: HTMLButtonElement;
  deviceSelector: HTMLSelectElement;
  thumbnailContainer: HTMLDivElement;
  emailSubject: string;
  coordinatorEmail: string;
  titleIconSrc: string;

  currentLocation: string;
  locationHref: string;
  locationSrc: string;
  inspectorLocationErrorMsg: string;
  inspectorLocationClass: string;
  constructor(private elementRef: ElementRef,
              private route: ActivatedRoute,
              private router: Router,
              private environmentService: EnvironmentService,
              private videoConnectionManagerService: VideoConnectionManagerService,
              private snapshotService: SnapshotService,
              private s3UploadsService: S3UploadsService,
              private logger: NGXLogger) { }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit(): void {
    const that = this;
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');
    this.initializeContactInfo();

    window.onbeforeunload = (event) => {
      if (that.sessionCompleted) return event;
      event.preventDefault;
      fetch(`${this.environmentService.apiUrl}/event`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sessionId: this.sessionId,
          type: 800, // COORDINATOR_TAB_CLOSED
          message: `browser tab closed by coordinator`
        })
      });
      const message = 'Are you sure you want to leave the session?';
      var e = e || window.event;
      if (e) {
        e.returnValue = message;
      }
      return message;
    };
    Loading.pulse('Connecting to session...');
    this.initializeStream().then(() => {
      this.videoConnectionManagerService.joinSession(this.sessionId, false, ParticipantSide.COORDINATOR, this.stream).then((token) => {
        if (!token || token.length < 1) {
          return;
        } else if (token == 'retryWithCreateOnCompleted') {
          Confirm.show('reinstate session confirmation',
            'This session has already ended. Would you like to reinstate this session?',
            'Reinstate', 'Cancel',
            () => {
              this.videoConnectionManagerService.joinSession(this.sessionId, true, ParticipantSide.COORDINATOR, this.stream).then(token => {
                if (!token || token.length < 1) {
                  return;
                }
                this.afterInitChecks();
              })
            },
            () => {
              window.onbeforeunload = () => { return };
              open(this.environmentService.appUrl, "_self").close();
          });
        } else {
          this.afterInitChecks();
        }

        this.isLoading = false;
        Loading.remove();
        this.thumbnailContainer = <HTMLDivElement>document.getElementById('thumbnail-container');
        this.captureButton = <HTMLButtonElement>document.getElementById('capture-image');
        this.recordButton = <HTMLButtonElement>document.getElementById('record-button');
        this.sessionButton = <HTMLButtonElement>document.getElementById('end-session');
        this.cancelSessionButton = <HTMLButtonElement>document.getElementById('cancel-session');
        this.deviceSelector = <HTMLSelectElement>document.getElementById('video-devices');
        this.statsMonitor = <HTMLButtonElement>document.getElementById('stats-monitor');
        this.chatButton = <HTMLButtonElement>document.getElementById('send-message');
        this.videoToggle = <HTMLButtonElement>document.getElementById('video-toggle');
        
        this.videoToggle.addEventListener('click', event => {
          event.preventDefault();
          that.toggleVideo();
        });
        this.chatButton.addEventListener('click', (event) => {
          event.preventDefault();
          that.videoConnectionManagerService.sendChat(ParticipantSide.COORDINATOR, this.chatButton);
        });
        document.getElementById('active-message').addEventListener('keypress', (event) => {
          if (event.keyCode === 13) {
            that.videoConnectionManagerService.sendChat(ParticipantSide.COORDINATOR, this.chatButton);
          }
        });

        this.statsMonitor.addEventListener('click', (event) => {
          event.preventDefault();
          window['Twilio'].VideoRoomMonitor.toggleMonitor();
        });

        this.captureButton.addEventListener('click', (event) => {
          event.preventDefault();
          that.handleCaptureButtonClick();
        });
        this.recordButton.addEventListener('click', (event) => {
          event.preventDefault();
          that.handleRecordButtonClick();
        });
        this.sessionButton.addEventListener('click', (event) => {
          event.preventDefault();
          that.endSession('End');
        });
        this.cancelSessionButton.addEventListener('click', (event) => {
          event.preventDefault();
          that.endSession('Cancel');
        });
        this.deviceSelector.addEventListener('change', () => {
          that.updateVideoDevice();
        });
        this.videoConnectionManagerService.coordinatorCommand$.subscribe((message) => {
          switch (message.command) {
            case DataCommand.CAPTURE_IMAGE_ACK:
              that.handleThumbnailAck(message.data.imageId);
              break;
            case DataCommand.CAPTURE_IMAGE_COMPLETE:
              that.handleImageCapture(message.data.imageId, message.data.base64);
              that.captureButton.disabled = false;
              that.sessionButton.disabled = false;
              break;
            case DataCommand.CAMERA_SWITCH_COMPLETED:
              delayInMs(800).then(() => {
                this.deviceSelector.disabled = false;
                this.captureButton.disabled = false;
              });
              break;
            case DataCommand.IMAGE_UPLOAD_COMPLETE:
              this.snapshotService.markUploadComplete(message.data.imageId, message.data.url, ParticipantSide.COORDINATOR);
              break;
            case DataCommand.SEND_CHAT_MESSAGE:
              appendChat(message.data.side, message.data.content);
              break;
            case DataCommand.INSPECTOR_LOCATION_DATA:
              if(message.data.success) {
                this.updateLocation(message.data.latitude, message.data.longitude, !this.mapUploadedToS3)
              } else {
                this.inspectorLocationErrorMsg = message.data.error;
                this.inspectorLocationClass = 'warn'
                if (!this.inspectorLocationError) {
                  Notify.warning('Location error: ' + message.data.error, { position: 'center-top' });
                  this.inspectorLocationError = true;
                  this.inspectorLocationObtained = false;
                }
              }
              break;
            default:
              break;
          }
        });
        this.videoConnectionManagerService.inspectorJoined$.subscribe(flag => {
          this.isInspectorConnected = flag;
        })
        this.videoConnectionManagerService.sessionTimeout$.subscribe(message => {
          if (message) {
            that.sessionCompleted = true;
            that.router.navigate(['/end-session/co/0/0']);
          }
        })
      });
    })
  }

  afterInitChecks() {
    this.roomSid = this.videoConnectionManagerService.getRoomInfo().sid;
    this.performRecordingCheck();
    this.performPermissionCheck();
    this.getExistingMedia();
    this.videoConnectionManagerService.timeoutWithoutParticipant(this.sessionId, ParticipantSide.INSPECTOR)
  }

  updateLocation(latitude: number, longitude: number, updateMapImage: boolean) {
    this.currentLocation = latitude + ',' + longitude
    this.inspectorLocationClass = 'coordinates';
    this.locationHref = this.getLocationUrl(latitude, longitude)
    if (updateMapImage) {
      fetch(`${this.environmentService.apiUrl}/map-image/${this.sessionId}/${this.roomSid}`, {
        method: 'GET',
        headers: {
          'Accept': '*/*'
        }
      }).then(res => {
        if (res.status < 299) {
          res.json().then(json => {
            if (json.url && json.url != '') {
              this.locationSrc = json.url;
              if (!this.inspectorLocationObtained) {
                Notify.success('Successfully obtained inspector location', { position: 'center-top' });
                this.inspectorLocationObtained = true;
              }
            }
          })
        }
      })
    }
  }

  getLocationUrl(latitude: number, longitude: number): string {
    const convert = window['convert'];
    const converted = convert(latitude + ', ' + longitude);
    const encoded = encodeURI(converted.toCoordinateFormat(convert.to.DMS))
    return 'https://www.google.com/maps/place/' + encoded + '/@' + latitude + ',' + longitude + ',14z'
  }

  async initializeStream() {
    this.stream = await navigator.mediaDevices.getUserMedia(CoordinatorStreamConstraints);
  }

  initializeContactInfo() {
    fetch(`${this.environmentService.apiUrl}/contact-info/${this.sessionId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.status < 299) {
        res.json().then(json => {
          this.emailSubject = json.emailSubject;
          this.coordinatorEmail = json.coordinatorEmail;
          this.titleIconSrc = EditIconSrc;
          const subjectDiv = <HTMLDivElement>document.querySelector('.subject');
          const iconImage = <HTMLImageElement>document.getElementById('subject-icon');
        })
      }
    }, reason => {
      this.logger.warn('Contact info not found for this session', reason)
    })
  }

  handleSubjectClick() {
    const iconImage = <HTMLImageElement>document.getElementById('subject-icon');
    iconImage.src = SaveIconSrc;
  }

  handleIconClick() {
    const iconImage = <HTMLImageElement>document.getElementById('subject-icon');
    const oldSubject = this.emailSubject;
    if (iconImage.src.includes('save-icon')) {
      const heading = <HTMLHeadingElement> document.querySelector('.subject h3');
      heading.textContent = heading.textContent.trim();
      if (heading.textContent.length > 40) {
        Notify.warning('Subject is too long. Trimming to first 40 characters', { position: 'center-top' });
        heading.textContent = heading.textContent.substring(0, 40);
      }
      this.emailSubject = heading.textContent;
      fetch(`${this.environmentService.apiUrl}/contact-info`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sessionId: this.sessionId,
          emailSubject: this.emailSubject
        })
      }).then(res => {
        iconImage.src = EditIconSrc;
        if (res.status > 299) {
          Notify.warning('Unable to update subject.', { position: 'center-top' });
          // revert to prior subject
          heading.textContent = oldSubject;
          this.emailSubject = oldSubject;
        } else {
          Notify.success('Subject updated successfully', { position: 'center-top' });
        }
      })
    } else {
      iconImage.src = SaveIconSrc;
    }
  }

  handleHelpButtonClick() {
    const feedback = <HTMLDivElement> document.querySelector('.feedback-component');
    if (feedback) feedback.style.display = "flex";
  }

  handleCaptureButtonClick() {
    this.captureButton.disabled = true;
    this.videoConnectionManagerService.sendDataCommand({
      command: DataCommand.CAPTURE_IMAGE,
      data: null
    });
    // enable the capture button after a fixed time limit if not already done
    delayInMs(10000).then( () => {
      this.resetCaptureButton();
    });
  }

  handleRecordButtonClick(): void {
    const action = this.recordButton.getAttribute('class');
    if (action === 'start-recording'){
      this.videoConnectionManagerService.startRecording(this.recordButton, 0);
    } else if (action === 'stop-recording') {
      this.videoConnectionManagerService.stopRecording(this.recordButton);
      this.sessionButton.disabled = false;
    } else {
      console.log('Action not implemented');
    }
  }

  async performRecordingCheck() {
    const that = this;
    var roomInfo = this.videoConnectionManagerService.getRoomInfo();
    while (!roomInfo) {
      await delayInMs(5000);
      roomInfo = this.videoConnectionManagerService.getRoomInfo();
    }
    this.isInspectorConnected = roomInfo && roomInfo.participants && roomInfo.participants.size > 0
    if (roomInfo.isRecording) {
      that.handleRecordButtonClick();
    }
  }

  async performPermissionCheck() {
    var roomInfo = this.videoConnectionManagerService.getRoomInfo();
    while (roomInfo && 
      (!roomInfo.participants || roomInfo.participants.size < 1) && 
      (!this.inspectorDeniedCamera || !this.inspectorDeniedMic)) {
      await delayInMs(5000);
      // check for denied permissions by inspector
      const response = await fetch(`${this.environmentService.apiUrl}/inspector-permission-status/${this.sessionId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      if (response.status < 299) {
        const permissionStatus = await response.json()
        if (permissionStatus.permissionDenied) {
          const required = (permissionStatus.requiredPermission == 910) ? 'camera' : 'microphone';
          const alreadyDenied = (required == 'camera') ? this.inspectorDeniedCamera : this.inspectorDeniedMic;
          roomInfo = this.videoConnectionManagerService.getRoomInfo();
          
          // only show the warning for a denied pemission once
          if (!alreadyDenied && (!roomInfo.participants || roomInfo.participants.size < 1)) {
            Report.warning(
              'Inspector Denied Permission',
              `The inspector has denied ${required} permission on their device. Kindly ask them to rectify this issue on their end.`,
              'Ok'
            )
            if (required == 'camera') {
              this.inspectorDeniedCamera = true;
            } else {
              this.inspectorDeniedMic = true;
            }
          }
        }
      }
    }
  }

  handleImageCapture(imageId: string, base64: string): void {
    const placeholder = document.getElementById(imageId);
    if (placeholder) {
      placeholder.remove();
    }
    this.snapshotService.captureThumbnail(this.thumbnailContainer, imageId, base64);
    this.uploadMapImageToS3();
  }

  async getExistingMedia() {
    let response = await fetch(`${this.environmentService.apiUrl}/media/${this.sessionId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    let media = await response.json();
    if (media && media.length && media.length > 0) {
      const imagesDiv = <HTMLDivElement> document.getElementById('thumbnail-container');
      media.forEach(image => {
        if (image.url && image.type && image.type == 'image/jpeg') {
          const imageId = 'img-' + image.name.split('-')[0];
          this.snapshotService.appendImage(imagesDiv, imageId, image.url, ThumbnailImageSettings.height);
          delayInMs(100).then(() => {
            this.snapshotService.markUploadComplete(imageId, image.url, ParticipantSide.COORDINATOR);
          });
        }
      });
    }
  }

  handleThumbnailAck(imageId: string): void {
    const existing = document.getElementById(imageId);
    if (!existing) {
      const placeholder = new Image();
      placeholder.src = '../assets/icons/thumbnail-96x96.jpg';
      placeholder.id = imageId;
      this.thumbnailContainer.append(placeholder);
    } else {
      this.logger.warn('Skipping thumbnail placeholder. Image already exists.', {sessionId: this.sessionId, data: existing});
    }
  }

  resetCaptureButton(): void {
    if(this.captureButton.disabled) {
      console.log('reseting capture button');
      this.captureButton.disabled = false;
    }
  }
  resetDeviceSelector(): void {
    if (this.deviceSelector.disabled) {
      console.log('reseting device selector')
      this.deviceSelector.disabled = false;
    }
  }

  updateVideoDevice() {
    if (this.deviceSelector.value) {
      this.deviceSelector.disabled = true;
      this.captureButton.disabled = true;
      this.videoConnectionManagerService.sendDataCommand({
        command: DataCommand.CAMERA_SWITCH_REQUESTED,
        data: {facingMode: this.deviceSelector.value}
      });
      delayInMs(10000).then( () => {
        this.resetDeviceSelector();
        this.resetCaptureButton();
      });
    }
  }
  endSession(action: string) {
    var that = this;
    this.uploadMapImageToS3();
    this.sessionButton.disabled = true;
    this.cancelSessionButton.disabled = true;
    const reason = action.toLowerCase() + '-session'
    Confirm.show(action + ' session confirmation',
    'Are you sure you want to ' + action.toLowerCase() + ' this session?',
    action + ' Session', 'Go Back',
    () => {
      this.videoConnectionManagerService.completeRoom(reason).then(() => {
        this.stream.getVideoTracks().forEach(track => {
          track.stop();
        });
        that.videoConnectionManagerService.createComposition().then(() => {
          that.sessionCompleted = true;
          if (action == 'End') {
            that.router.navigate(['/end-session/coordinator/' + that.roomSid + '/' + that.sessionId]);
          } else {
            window.close();
          }
        })
      })
    },
    () => {
      that.sessionButton.disabled = false;
      that.cancelSessionButton.disabled = false;
    });
  }

  toggleVideo() {
    this.videoToggle.disabled = true;
    if (this.videoEnabled) {
      this.videoConnectionManagerService.disableVideo();
      this.logger.trace('Disabling coordinator video for session.', {sessionId: this.sessionId});
      delayInMs(2000).then(() => {
        this.videoToggle.innerHTML = 'Enable Coordinator Video';
        this.videoEnabled = false;
        this.videoToggle.disabled = false;
      });
    } else {
      navigator.mediaDevices.getUserMedia(CoordinatorStreamConstraints).then((stream) => {
        this.videoConnectionManagerService.rePublishVideo(stream.getVideoTracks()[0]);
      //return this.videoConnectionManagerService.joinSession(this.sessionId, ParticipantSide.COORDINATOR, stream);
    })
      // videoElement.play();
      this.logger.trace('Enabling coordinator video for session.', {sessionId: this.sessionId});
      delayInMs(2000).then(() => {
        this.videoToggle.innerHTML = 'Disable Coordinator Video';
        this.videoEnabled = true;
        this.videoToggle.disabled = false;
      });
    }
  }

  uploadMapImageToS3() {
    if (this.inspectorLocationObtained && this.currentLocation && this.locationSrc && this.locationSrc.length > 8) {
      if (!this.mapUploadedToS3) {
        const data: Blob = convertBase64ToBlob(this.locationSrc)
        this.s3UploadsService.upload(data, this.sessionId, this.roomSid, null).then(S3Object => {
          this.mapUploadedToS3 = true;
        })
      }
    }
  }
}
