import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EnvironmentService } from './environment.service';
import { Headers, delayInMs } from '../interfaces/app-constants';
import { VideoConnectionManagerService } from './video-connection-manager.service';
import { DataCommand } from 'app/enums/data-command.enum';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private options = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 20000
  };
  private lastPosition: GeolocationPosition;
  private locationErrorEventLogged: boolean;
  private cleanupPerformed: boolean;
  constructor(private environmentService: EnvironmentService, private logger: NGXLogger) { 
    this.locationErrorEventLogged = false;
    this.cleanupPerformed = false;
  }

  uploadLocation(sessionId: string, roomSid: string, coordinates: GeolocationCoordinates) {
    fetch(`${this.environmentService.apiUrl}/location`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({ 
        sessionId: sessionId,
        roomSid: roomSid,
        coordinates: [coordinates.latitude, coordinates.longitude, coordinates.accuracy]
      }),
    }).then(res => {
      this.logger.trace('Logged geo coordinates', {sessionId, coordinates});  
    })
  }

  public getPosition(): Promise<GeolocationCoordinates | GeolocationPositionError> {
    let that = this;
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.lastPosition = position;
        resolve(position.coords);
      },
      (error: GeolocationPositionError) => {
        reject(error);
      }, this.options)
    });
  }

  public getCachedPosition(): GeolocationCoordinates {
    if (this.lastPosition && this.lastPosition.coords && this.lastPosition.coords.latitude != 0) {
      return this.lastPosition.coords;
    }
    return null;
  }

  public async handleLocationLogging(sessionId: string, roomSid: string, connectionManager: VideoConnectionManagerService) {
    if (!navigator.geolocation) {
      this.logger.error('geolocation feature unavailable', {sessionId});
      return;
    }
    while (navigator.geolocation && !this.cleanupPerformed) {
      this.getPosition().then(coordinates => {
        const coords = <GeolocationCoordinates>coordinates;
        // skip sending coordinates if device captures a [0,0] / null location due to GPS malfunction
        if (coords.latitude > 0.1 || coords.latitude < -0.1 || coords.longitude > 0.1 || coords.longitude < -0.1) {
          this.uploadLocation(sessionId, roomSid, coords);
          connectionManager.sendDataCommand({
            command: DataCommand.INSPECTOR_LOCATION_DATA,
            data: {
              'success': true,
              'latitude': coords.latitude,
              'longitude': coords.longitude
            }
          })
        }
      }, error => {
        this.logger.error('Unable to obtain geolocation', { sessionId, error });
        if (!this.locationErrorEventLogged) {
          fetch(`${this.environmentService.apiUrl}/event`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              sessionId,
              type: 930, // LOCATION_FETCH_ERROR
              message: error.message
            })
          }).then(res => {
            this.locationErrorEventLogged = true;
          })
        }
        connectionManager.sendDataCommand({
          command: DataCommand.INSPECTOR_LOCATION_DATA,
          data: {
            'success': false,
            'error': error.message
          }
        });
      })
      await delayInMs(30000);
    }
  }

  public logUserAgent(sessionId: string) {
    fetch(`${this.environmentService.apiUrl}/event`, {
      method: 'post', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        sessionId: sessionId, 
        type: 30, // INSPECTOR_JOINED
        message: navigator.userAgent
      })
    });
  }

  public stopLocationLogging() {
    this.cleanupPerformed = true;
  }
}
