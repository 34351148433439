import { Injectable } from '@angular/core';
import Video = require('twilio-video');
import { ParticipantSide } from '../enums/participant-side.enum';
import { Subject, Observable } from 'rxjs';
import { RemoteCommand } from '../interfaces/remote-command.interface';
import { EnvironmentService } from './environment.service';
import { appendChat, delayInMs, Headers, RearFacing } from '../interfaces/app-constants';
import { DataCommand } from 'app/enums/data-command.enum';
import { NGXLogger } from 'ngx-logger';
import { Report, Notify, Loading, Block } from 'notiflix';
import { generateId } from 'app/interfaces/app-constants';
@Injectable({
  providedIn: 'root'
})
export class VideoConnectionManagerService {

  private room: Video.Room
  private dataTrack: Video.LocalDataTrack
  private participant: ParticipantSide
  private coordinatorSubject: Subject<RemoteCommand>
  private sessionCompletedSubject: Subject<boolean>
  private sessionTimeoutSubject: Subject<boolean>
  private inspectorJoinedSubject: Subject<boolean>
  public coordinatorCommand$: Observable<RemoteCommand>
  public sessionCompleted$: Observable<boolean>
  public sessionTimeout$: Observable<boolean>
  public inspectorJoined$: Observable<boolean>

  constructor(private environmentService: EnvironmentService, private logger: NGXLogger) {
    this.dataTrack = new Video.LocalDataTrack();
    this.coordinatorSubject = new Subject<RemoteCommand>();
    this.sessionCompletedSubject = new Subject<boolean>();
    this.sessionTimeoutSubject = new Subject<boolean>();
    this.inspectorJoinedSubject = new Subject<boolean>();
    this.coordinatorCommand$ = this.coordinatorSubject.asObservable();
    this.sessionCompleted$ = this.sessionCompletedSubject.asObservable();
    this.sessionTimeout$ = this.sessionTimeoutSubject.asObservable();
    this.inspectorJoined$ = this.inspectorJoinedSubject.asObservable();
  }

  public createSession = async (
    sessionId: string,
    coordinatorEmail: string,
    coordinatorLink: string,
    inspectorEmail: string,
    inspectorPhoneNumber: string,
    inspectorLink: string,
    emailSubject: string,
    webHookUrl: string = this.environmentService.webHookUrl,
  ) => {
    await fetch(`${this.environmentService.apiUrl}/session`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        sessionId,
        coordinatorEmail,
        coordinatorLink,
        inspectorEmail,
        inspectorPhoneNumber,
        inspectorLink,
        emailSubject,
        webHookUrl,
        hostFqdn: this.environmentService.appHost 
      }),
    });
  }

  public joinSessionResponse = async (sessionId: string, role: ParticipantSide, createOnCompleted: boolean) => {
    return await fetch(`${this.environmentService.apiUrl}/join-session`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        sessionId, role, createOnCompleted
      }),
    });
  }

  public joinSession = async (sessionId: string, createOnCompleted: boolean,
                              participantSide: ParticipantSide, stream: MediaStream): Promise<string> => {
    this.participant = participantSide;
    if (!stream) {
      this.logger.error('Unable to join session without video stream', {sessionId});
      return '';
    }
    // fetch an Access Token from the join-room route
    const response = await this.joinSessionResponse(sessionId, participantSide, createOnCompleted);
    const { success, message, token } = await response.json();

    if (!success && message != 'completed room') {
      let message = 'Something went wrong while trying to connect to the session. ';
      if (participantSide == ParticipantSide.COORDINATOR) {
        message += 'Please verify the session ID and try again.';
      } else {
        message += 'Please ensure that the coordinator is connected and try again.';
      }
      Loading.remove();
      Report.failure(
        'Unable to Join the Session',
        message,
        'Close',
        () => {
          window.onbeforeunload = () => { return };
          open(this.environmentService.appUrl, "_self").close();
        }
      );
      return '';
    } else if (!success && !createOnCompleted) {
      return 'retryWithCreateOnCompleted';
    } else {
      const videoTrack: MediaStreamTrack = stream.getVideoTracks()[0];
      let audioStream: MediaStream
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      } catch (e) {
        Report.failure('Unable to obtain audio permissions',
        'Go Video requires access to camera and microphone to conduct an inspection. Kindly refresh your page and allow the permissions',
        'Okay')
        this.logger.error('Unable to obtain audio stream', {sessionId, error: e});
        fetch(`${this.environmentService.apiUrl}/event`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            sessionId: sessionId,
            type: 920, // MICROPHONE_PERMISSION_DENIED
            message: `microphone access denied by ${participantSide}`
          })
        });
        Loading.remove()
      }
      if (!audioStream) return '';
      const audioTrack: MediaStreamTrack = audioStream.getAudioTracks()[0];
      // join the video room with the token
      const room = await this.joinVideoSession(sessionId, token, videoTrack, audioTrack, participantSide);
      this.room = room;
      window['Twilio'].VideoRoomMonitor.registerVideoRoom(room);
      console.log('Room SID: ' + room.sid);
      // render the local and remote participants' video and audio tracks
      switch (participantSide) {
        case ParticipantSide.COORDINATOR:
          this.handleConnectedCoordinator(room.localParticipant);
          room.participants.forEach(this.handleConnectedInspector)
          room.on("participantConnected", this.handleConnectedInspector)
          room.on("participantDisconnected", this.handleDisconnectedInspector);
          break;
        case ParticipantSide.INSPECTOR:
          this.handleConnectedInspector(room.localParticipant);
          room.participants.forEach(this.handleConnectedCoordinator)
          room.on("participantConnected", this.handleConnectedCoordinator)
          room.on("participantDisconnected", this.handleDisconnectedCoordinator);
          room.on("disconnected", () => {
            this.sessionCompletedSubject.next(true);
          });
          break;
        default:
          this.logger.warn('Unknown participant side.', { sessionId, participant: participantSide });
          break;
      }
      room.on("trackSwitchedOff", (data) => {
        console.warn('track switched off');
        console.warn(data);
      });
      room.on("trackSwitchedOn", (data) => {
        console.warn('track switched back on');
        console.warn(data);
      });
      room.on("trackDisabled", (data) => {
        console.warn('track disabled');
        console.warn(data);
      })
      room.on("trackEnabled", (data) => {
        console.log('track re-enabled');
        console.warn(data);
      });
      window.addEventListener('beforeunload', () => room.disconnect());
      window.addEventListener('pagehide', () => room.disconnect());
      return token;
    }
  };

  public handleConnectedInspector = (participant: Video.Participant) => {
    this.inspectorJoinedSubject.next(true);
    participant.on('networkQualityLevelChanged', this.updateInspectorNetworkStats)
    const splashScreen = <HTMLDivElement> document.getElementById('inspector-video-splash-screen');
    const videoContainer = <HTMLDivElement> document.querySelector('.inspector-video');
    this.handleConnectedParticipant(participant, videoContainer, splashScreen);

    if (this.participant == ParticipantSide.COORDINATOR) {
      const captureButton = <HTMLButtonElement> document.getElementById('capture-image');
      if (captureButton)  captureButton.disabled = false;
      const recordingButton = <HTMLButtonElement> document.getElementById('record-button');
      if (recordingButton) {
        recordingButton.disabled = false;
        const action = recordingButton.getAttribute('class');
        if (action === 'stop-recording') {
          this.showRecordingIcon();
        }
      }
      const selector : HTMLSelectElement = <HTMLSelectElement> document.getElementById('video-devices');
      if (selector) selector.disabled = false;
      const chatButton : HTMLButtonElement = <HTMLButtonElement> document.getElementById('send-message');
      if (chatButton) chatButton.disabled = false;
    } else {
      this.addInspectorSID(participant.sid);
    }

    Notify.success('Inspector Session Connected', { position: 'center-top' });
  };

  public handleConnectedCoordinator = (participant: Video.Participant) => {
    participant.on('networkQualityLevelChanged', this.updateCoordinatorNetworkStats)
    const splashScreen = <HTMLDivElement> document.getElementById('coordinator-video-splash-screen');
    const videoContainer = <HTMLDivElement> document.querySelector('.coordinator-video');
    this.handleConnectedParticipant(participant, videoContainer, splashScreen);
    if (this.participant == ParticipantSide.INSPECTOR) {
      const chatButton : HTMLButtonElement = <HTMLButtonElement> document.getElementById('send-message');
      if (chatButton) chatButton.disabled = false;
    } else {
      this.addCoordinatorSID(participant.sid);
    }
    Notify.success('Coordinator Session Connected', { position: 'center-top' });
  }

  public handleDisconnectedInspector = (participant: Video.Participant) => {
    // const splashScreen = <HTMLDivElement> document.getElementById('inspector-video-splash-screen');
    this.inspectorJoinedSubject.next(false);
    this.handleDisconnectedParticipant(participant);
    const captureButton = <HTMLButtonElement> document.getElementById('capture-image');
    if (captureButton) captureButton.disabled = true;
    Notify.failure('Inspector Session Disconnected', { position: 'center-top' });
  }

  public handleDisconnectedCoordinator = (participant: Video.Participant) => {
    // const splashScreen = <HTMLDivElement> document.getElementById('coordinator-video-splash-screen');
    console.log('Coordinator disconnected');
    this.handleDisconnectedParticipant(participant);
    Notify.failure('Coordinator Session Disconnected', { position: 'center-top' });
  }

  public handleConnectedParticipant = (participant: Video.Participant,
                                      participantDiv: HTMLDivElement,
                                      splashScreen: HTMLDivElement) => {
    participantDiv.setAttribute('id', participant.identity);
    participantDiv.hidden = false;

    // iterate through the participant's published tracks and
    // call `handleTrackPublication` on them
    participant.tracks.forEach((trackPublication) => {
      this.handleTrackPublication(trackPublication, participant);
    });
    if (splashScreen) { splashScreen.remove(); }
    // listen for any new track publications
    participant.on("trackPublished", this.handleTrackPublication);
  };

  public attachVideoTrack(participantDiv: HTMLDivElement, track: Video.VideoTrack) {
    const currentHeight = participantDiv.offsetHeight + 16;
    participantDiv.style.height = currentHeight + 'px';
    const videoTrack = track.attach();
    // videoTrack.defaultMuted = true;
    videoTrack.style.width = '100%';
    videoTrack.style.maxWidth = '1280px';
    videoTrack.style.maxHeight = '960px';
    const videoEles = participantDiv.getElementsByTagName('video');
    if (videoEles && videoEles.length > 0) {
      const existingVideo = videoEles[0];
      existingVideo.remove();
    }
    participantDiv.append(videoTrack);
    participantDiv.style.height = null;
  }

  public handleDisconnectedParticipant = (participant: Video.Participant) => {
    // stop listening for this participant
    participant.removeAllListeners();
    // remove this participant's div from the page
    const participantDiv = document.getElementById(participant.identity);
    if (participantDiv) {
      participantDiv.innerHTML = '';
      participantDiv.hidden = true;
    }

    // disable and reset device selector
    if (this.participant == ParticipantSide.COORDINATOR) {
      const selector : HTMLSelectElement = <HTMLSelectElement> document.getElementById('video-devices');
      if (selector && selector.value) {
        selector.disabled = true;
        selector.value = RearFacing;
      }
    }

    // disable chat button
    const chatButton : HTMLButtonElement = <HTMLButtonElement> document.getElementById('send-message');
    if (chatButton) chatButton.disabled = true;

  };

  public handleTrackPublication = (trackPublication, participant) => {
    const that = this;
    function displayTrack(track) {
      if (track.kind === 'audio' || track.kind === 'video') {
        // append this track to the participant's div and render it on the page
        let participantDiv: HTMLDivElement;
        if (participant && participant.identity) {
          participantDiv = <HTMLDivElement> document.getElementById(participant.identity);
        } else if (trackPublication.trackName && trackPublication.trackName.includes(ParticipantSide.COORDINATOR.toString())) {
          participantDiv = <HTMLDivElement> document.querySelector('.coordinator-video');
        } else if (trackPublication.trackName && trackPublication.trackName.includes(ParticipantSide.INSPECTOR.toString())) {
          participantDiv = <HTMLDivElement> document.querySelector('.inspector-video');
        } else {
          that.logger.warn('Unable to fetch participant Div in displayTrack()',
          {sessionId: that.room.name, trackPublication});
          return;
        }

        if (track.kind === 'video') {
          that.attachVideoTrack(participantDiv, track);
        } else {
          // track.attach creates an HTMLVideoElement or HTMLAudioElement
          // (depending on the type of track) and adds the video or audio stream
          participantDiv.append(track.attach());
        }
      } else if (track.kind === 'data') {
        track.on('message', data => that.receiveDataCommand(data));
      }
    }

    // check if the trackPublication contains a `track` attribute. If it does,
    // we are subscribed to this track. If not, we are not subscribed.
    if (trackPublication.track) {
      displayTrack(trackPublication.track);
    }

    // listen for any new subscriptions to this track publication
    trackPublication.on("subscribed", displayTrack);
  };

  public joinVideoSession = async (sessionId, token, videoTrack: MediaStreamTrack,
    audioTrack: MediaStreamTrack, participantSide: ParticipantSide) => {
    // join the video room with the Access Token and the given room name
    let tracks = <(Video.LocalTrack)[]> [];
    const localVideoTrack = new Video.LocalVideoTrack(videoTrack, { name: this.getTrackName(participantSide, 'video')});
    const localAudioTrack = new Video.LocalAudioTrack(audioTrack, { name: this.getTrackName(participantSide, 'audio')});
    tracks.push(this.dataTrack);
    tracks.push(localVideoTrack);
    const session = await Video.connect(token, {
      name: sessionId,
      tracks: tracks,
      maxVideoBitrate: 1024 * 1024,
      maxAudioBitrate: 16 * 1024,
      video: { height: {min: 180, max: 540}, frameRate: {min: 2, max: 15}, width: {min: 240, max: 720} },
      bandwidthProfile: {
        video: {
          maxSubscriptionBitrate: 1024 * 1024,
        }
      },
      networkQuality: {
        local: 2,
        remote: 1
      }
    });
    await session.localParticipant.publishTrack(localAudioTrack, {priority: 'high'});
    fetch(`${this.environmentService.apiUrl}/event`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sessionId: sessionId,
        type: 40, // CONNECTION_ESTABLISHED
        message: `audio-video connection established by ${participantSide}`
      })
    });
    return session;
  };

  public sendDataCommand(message: RemoteCommand) {
    this.dataTrack.send(JSON.stringify(message));
  }

  public sendChat(participantSide: ParticipantSide, chatButton: HTMLButtonElement) {
    const chatInput = <HTMLInputElement> document.getElementById('active-message');
    if (!chatInput || !chatInput.value || chatInput.value.length < 1) {
      return;
    }
    chatButton.disabled = true;
    this.sendDataCommand({
      command: DataCommand.SEND_CHAT_MESSAGE,
      data: {
        side: participantSide,
        content: chatInput.value
      }
    });
    appendChat(participantSide, chatInput.value);
    this.playAudio();
    delayInMs(200).then(() => {
      chatInput.value = '';
      chatButton.disabled = false;
    });
  }

  public playAudio() {
    let audio = <HTMLAudioElement> document.querySelector('#chat-controls audio')
    audio.src = '../../assets/sounds/notification-sound.mp3';
    audio.load();
    audio.play();
  }

  public receiveDataCommand(message: string) {
    const remoteCmd = <RemoteCommand> JSON.parse(message);
    this.coordinatorSubject.next(remoteCmd);
    if (remoteCmd.command == DataCommand.SEND_CHAT_MESSAGE) {
      this.playAudio();
    }
  }

  public stopTracks(stopAudio?: boolean) {
    const localParticipant = this.room.localParticipant;
    const trackPublications = Array.from(stopAudio? localParticipant.tracks.values() : localParticipant.videoTracks.values());
    trackPublications.forEach((trackPublication) => {
      if(trackPublication.track) {
        const track = trackPublication.track;
        localParticipant.unpublishTrack(track);
      }
    });
  }

  public publishVideoTrack(stream: MediaStream, side: ParticipantSide) {
    const [videoTrack] = stream.getVideoTracks();
    const localVideoTrack = new Video.LocalVideoTrack(videoTrack, {name: this.getTrackName(side.toString(), 'video')});
    this.room.localParticipant.publishTrack(localVideoTrack);
  }

  public async startRecording(recordingButton: HTMLButtonElement, retryCount: number) {
    const that = this;
    const maxRetryCount = 3;
    if (this.room.isRecording) {
      this.logger.info('Room is already in recording state. Skipping', { sessionId: this.room.name, roomSid: this.room.sid, retryCount })
      recordingButton.setAttribute('class', 'stop-recording');
      recordingButton.innerHTML = "Stop Recording";
      recordingButton.disabled = false;
      this.showRecordingIcon();
      Block.remove('.inspector-video');
    } else {
      recordingButton.disabled = true;
      Block.standard('.inspector-video', 'Starting recording. Please wait..')
      fetch(`${this.environmentService.apiUrl}/start-recording`, {
        method: "POST",
        headers: Headers,
        body: JSON.stringify({
          roomSid: this.room.sid
        }),
      }).then(
        (res) => {
          delayInMs(1000).then(() => {
            this.logger.info('Is Recording started check', { isRecording: that.room.isRecording, sessionId: this.room.name, roomSid: that.room.sid, retryCount })
            if (that.room.isRecording) {
              recordingButton.setAttribute('class', 'stop-recording');
              recordingButton.innerHTML = "Stop Recording";
              recordingButton.disabled = false;
              this.showRecordingIcon();
              Notify.success('Started recording', { position: 'center-top' });
              Block.remove('.inspector-video');
              fetch(`${this.environmentService.apiUrl}/event`, {
                method: 'post', 
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                  sessionId: that.room.name, 
                  type: 150, // VIDEO_RECORDING_STARTED
                  message: 'video recording started'
                })
              });
            } else if (retryCount < maxRetryCount) {
              that.startRecording(recordingButton, retryCount + 1)
            } else {
              this.logger.error('Unable to start recording. Retries exhausted', { isRecording: that.room.isRecording, sessionId: that.room.name, retryCount })
              Notify.failure('Error starting recording', { position: 'center-top' });
              Block.remove('.inspector-video');
              delayInMs(1000).then(() => recordingButton.disabled = false);
            }
          })
        },
        (err) => {
          this.logger.error('Error starting recording', { sessionId: this.room.name, error: err });
          if (retryCount < maxRetryCount - 1) {
            Notify.warning('Unable to start recording. Re-trying')
            this.logger.warn('Retrying start recording', { sessionId: that.room.name, retryCount })
            delayInMs(2000).then(() => that.startRecording(recordingButton, retryCount + 1));
          } else {
            Notify.failure('Error starting recording', { position: 'center-top' });
            delayInMs(1000).then(() => recordingButton.disabled = false);
            Block.remove('.inspector-video');
          }
        })
    }
  }

  showRecordingIcon() {
    const recordIcon = document.getElementById('video-record-icon');
    if (recordIcon) {
      recordIcon.hidden = false;
    } else {
      const parentEle = <HTMLDivElement> document.querySelector(".inspector-video")
      let recordIcon = new Image();
      recordIcon.id = "video-record-icon";
      recordIcon.src = "../../assets/icons/video-record-icon.png"
      parentEle.prepend(recordIcon);
    }
  }

  public async stopRecording(recordingButton: HTMLButtonElement) {
    const that = this;
    recordingButton.disabled = true;
    Block.standard('.inspector-video', 'Stop recording')
    fetch(`${this.environmentService.apiUrl}/stop-recording`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        roomSid: this.room.sid
      }),
    }).then(
      (res) => {
        delayInMs(1000).then(() => {
          if (that.room.isRecording) {
            Notify.warning('Unable to stop recording. Try again', { position: 'center-top' });
            delayInMs(2000).then(() => recordingButton.disabled = false);
          } else {
            recordingButton.setAttribute('class', 'start-recording');
            recordingButton.innerHTML = "Start Recording";
            recordingButton.disabled = false;
            const icon = document.getElementById('video-record-icon');
            if (icon) { icon.hidden = true; }
            this.sendDataCommand({ command: DataCommand.VIDEO_RECORDING_STOPPED, data: null });
            Notify.success('Stopped recording', { position: 'center-top' });
            fetch(`${this.environmentService.apiUrl}/event`, {
              method: 'post', 
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ 
                sessionId: that.room.name, 
                type: 160, // VIDEO_RECORDING_STOPPED
                message: 'video recording stopped'
              })
            });
          }
          Block.remove('.inspector-video');
        })
      },
      (err) => {
        this.logger.error('Error trying to stop recording.', {sessionId: this.room.name, error: err});
        Block.remove('.inspector-video');
        Notify.warning('Unable to stop recording. Try again', { position: 'center-top' });
        delayInMs(2000).then(() => recordingButton.disabled = false);
  })}

  public async addInspectorSID(sid: string) {
    fetch(`${this.environmentService.apiUrl}/inspector-sid`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        sessionId: this.room.name,
        roomSid: this.room.sid,
        inspectorSid: sid,
      }),
    }).then(
      (res) => {
        this.logger.trace('Added inspector sid to be included in video composition', {sessionId: this.room.name, sid});
  })}

  public async addCoordinatorSID(sid: string) {
    fetch(`${this.environmentService.apiUrl}/coordinator-sid`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        sessionId: this.room.name,
        roomSid: this.room.sid,
        coordinatorSid: sid,
      }),
    }).then(
      (res) => {
        this.logger.trace('Added coordinator sid to be included in video composition', {sessionId: this.room.name, sid});
  })}

  public async createComposition() {
    fetch(`${this.environmentService.apiUrl}/create-composition`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({
        sessionId: this.room.name,
        roomSid: this.room.sid,
      }),
    }).then(
      (res) => {
        this.logger.info("created composition", { roomSid: this.room.sid, response: res });
      },
      (err) => {
        this.logger.error("error creating composition", { roomSid: this.room.sid, error: err })
  })}

  public async completeRoom(reason: string) {
    this.sendDataCommand({command: DataCommand.VIDEO_RECORDING_STOPPED, data: null});
    await fetch(`${this.environmentService.apiUrl}/complete-session`, {
      method: "POST",
      headers: Headers,
      body: JSON.stringify({ 
        roomSid: this.room.sid,
        reason: reason
      }),
    });
    this.logger.info('session ended', {sessionId: this.room.name});
  }

  // force connected participant to disconnect after 15 minutes if the other participant is not present on the call
  public async timeoutWithoutParticipant(sessionId: string, remoteParticipant: ParticipantSide) {
    let count = 0;
    const noParticipantTimeoutInMinutes = this.environmentService.timeoutInMinutes;
    this.logger.info('Running timeoutWithoutParticipant in background', {sessionId, noParticipantTimeoutInMinutes})
    while (count < noParticipantTimeoutInMinutes) {
      await delayInMs(60 * 1000); // 1 minute
      const info = this.getRoomInfo();
      if (info && info.participants && info.participants.size > 0) {
        count = 0;
      } else {
        count++;
      }
    }
    this.logger.warn(`Timeout due to ${remoteParticipant} not being connected`, {sessionId: sessionId})
    this.completeRoom(`no-${remoteParticipant}-timeout`);
    this.sessionTimeoutSubject.next(true);
  }

  public getRoomInfo(): Video.Room {
    return this.room;
  }

  public disableVideo() : void {
    this.room.localParticipant.videoTracks.forEach(publication => {
      publication.unpublish();
      publication.track.stop();
    });
  }

  public rePublishVideo(videoTrack: MediaStreamTrack) : void {
    const localVideoTrack = new Video.LocalVideoTrack(videoTrack, { name: this.getTrackName(ParticipantSide.COORDINATOR.toString(), 'video')});
    this.room.localParticipant.publishTrack(localVideoTrack);
  }

  public updateInspectorNetworkStats(networkQualityLevel: Video.NetworkQualityLevel, networkQualityStats: Video.NetworkQualityStats) {
    const level = networkQualityLevel | 1;
    const networkIconPaths = {
      0: '../../assets/icons/nq-0.png',
      1: '../../assets/icons/nq-1.png',
      2: '../../assets/icons/nq-2.png',
      3: '../../assets/icons/nq-3.png',
      4: '../../assets/icons/nq-4.png',
      5: '../../assets/icons/nq-5.png'
    };
    const imgId = 'inspector-network-quality';
    const containerClass = '.inspector-video';
    let qualityImg = <HTMLImageElement> document.getElementById(imgId);
    if (!qualityImg) {
      qualityImg = new Image();
      qualityImg.id = imgId;
      const videoContainer = <HTMLDivElement> document.querySelector(containerClass)
      videoContainer.append(qualityImg)
    }
    qualityImg.src = networkIconPaths[level];
  }

  public updateCoordinatorNetworkStats(networkQualityLevel: Video.NetworkQualityLevel, networkQualityStats: Video.NetworkQualityStats) {
    const level = networkQualityLevel | 1;
    const networkIconPaths = {
      0: '../../assets/icons/nq-0.png',
      1: '../../assets/icons/nq-1.png',
      2: '../../assets/icons/nq-2.png',
      3: '../../assets/icons/nq-3.png',
      4: '../../assets/icons/nq-4.png',
      5: '../../assets/icons/nq-5.png'
    };
    const imgId = 'coordinator-network-quality';
    const containerClass = '.coordinator-video';
    let qualityImg = <HTMLImageElement> document.getElementById(imgId);
    if (!qualityImg) {
      qualityImg = new Image();
      qualityImg.id = imgId;
      const videoContainer = <HTMLDivElement> document.querySelector(containerClass)
      videoContainer.append(qualityImg)
    }
    qualityImg.src = networkIconPaths[level];
  }

  private getTrackName(side: string, kind: string): string {
    const random = generateId(6);
    return side + '-' + kind + '-' + random;
  }
}
