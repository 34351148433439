<div class="feedback-component">
    <div class="feedback-modal">
        <div id="feedback-modal-body">
            <div class="form-field p-float-label">
                <input
                    class="p-inputtext-sm"
                    id="coordinator-email-container"
                    type="email"
                    [(ngModel)]="coordinatorEmail"
                    [required]="true"
                    [email]="true"
                    pInputText
                    pTooltip="contact details to reach you regarding your query"
                    tooltipPosition="right"
                >
                <label for="coordinator-email-container">Your email</label>
                <span class="required-asteric">*</span>
            </div>
            <div class="form-field p-float-label">
                <input
                    class="p-inputtext-sm"
                    id="email-subject-container"
                    [(ngModel)]="emailSubject"
                    [required]="true"
                    pInputText
                    pTooltip="subject of your GoVideo session for our reference"
                    tooltipPosition="right"
                    maxlength="40"
                >
                <label for="email-subject-container">Subject/Reference</label>
                <span class="required-asteric">*</span>
            </div>
            <div class="form-field p-float-label">
                <textarea
                    class="p-inputtext-sm"
                    id="description-container"
                    rows="4"
                    cols="30"
                    [(ngModel)]="description"
                    [required]="true"
                    pInputText
                    pTooltip="describe the issue that you are facing"
                    tooltipPosition="right"
                    [maxlength]="maxLength"
                    (change)="updateDescriptionLabel()"
                > </textarea>
                <label for="description-container">{{descriptionLabel}}</label>
                <span class="required-asteric">*</span>
            </div>
            <button id="submit"
            class="p-button-sm"
            type="button"
            pButton 
            (click)="submitFeedback()" 
            [disabled]="invalidModalState()">Submit</button>
            <button id="cancel" 
            class="p-button-sm"
            type="button"
            pButton
            (click)="cancel()">Cancel</button>
        </div>
    </div>
    <div class="feedback-modal-background"></div>
</div>