import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspector-video-container',
  templateUrl: './inspector-video-container.component.html',
  styleUrls: ['./inspector-video-container.component.css']
})
export class InspectorVideoContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
