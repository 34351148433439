import { idealHeight, idealWidth } from "./app-constants"

interface ImageSettingsInterface {
    quality: number,
    height: number,
    width: number,
    thumbnailHeight: number
}

const HiResImageSettings: ImageSettingsInterface = {
    quality: 0.85,
    height: idealHeight(),
    width: idealWidth(),
    thumbnailHeight: 78
}

const ThumbnailImageSettings: ImageSettingsInterface = {
    quality: 0.5,
    height: 96,
    width: 128,
    thumbnailHeight: 96
}

export { ImageSettingsInterface, HiResImageSettings, ThumbnailImageSettings }