import { Component, OnInit } from '@angular/core';
import { generateId, validateEmail } from 'app/interfaces/app-constants';
import { EnvironmentService } from '../services/environment.service';
import { VideoConnectionManagerService } from '../services/video-connection-manager.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  coordinatorEmail: string;
  coordinatorLink: string;
  inspectorEmail: string;
  inspectorPhoneNumber: string;
  emailSubject: string;
  inspectorLink: string;
  linkGenerated: boolean = false;
  generateButton: HTMLButtonElement;
  phoneNumberMaskedInput: HTMLInputElement;
  constructor(private environmentService: EnvironmentService,
    private videoConnectionManagerService: VideoConnectionManagerService) {
    }

  ngOnInit(): void {
    var that = this;
    this.generateButton = <HTMLButtonElement> document.getElementById('create-session');
    this.generateButton.addEventListener('click', event => {
      event.preventDefault;
      that.createSession();
    });
    this.inspectorEmail = '';
    this.inspectorPhoneNumber = '';
    this.phoneNumberMaskedInput = <HTMLInputElement> document.getElementById('inspector-phone-number').children[0];
  }

  createSession() {
    const sessionId = generateId(7);
    const coordinatorLink = this.environmentService.appUrl + '/coordinator/' + sessionId;
    const inspectorLink = this.environmentService.appUrl + '/inspector/' + sessionId;

    this.generateButton.disabled = true;
    // differentiate between adhoc session where an email will be sent.
    // should we create a new endpoint

    this.videoConnectionManagerService.createSession(
      sessionId,
      this.coordinatorEmail,
      coordinatorLink,
      this.inspectorEmail,
      this.inspectorPhoneNumber,
      inspectorLink,
      this.emailSubject,
    ).then(() => {
      this.coordinatorLink = coordinatorLink;
      this.inspectorLink = inspectorLink;
      this.linkGenerated = true;
    });
  }

  generateInspectorMessage() {
    return `${this.inspectorEmail ? 'Email' : ''}
      ${this.inspectorEmail && this.inspectorPhoneNumber ? 'and' : ''}
      ${this.inspectorPhoneNumber ? 'SMS' : ''}
      sent to the device holder with their corresponding link.`
  }

  invalidFormState() {
    return (
      (!validateEmail(this.coordinatorEmail)) ||
      (this.inspectorEmail !== '' && !validateEmail(this.inspectorEmail)) ||
      (this.inspectorPhoneNumber.replace(/\D+/g, '').length !== 10)
    );
  }

  resetCaretPosition() {
    this.phoneNumberMaskedInput.setSelectionRange(0, 0);
  }

  getHelp() {
    const modal = <HTMLDivElement> document.querySelector('.feedback-component');
    if (modal) {
      modal.style.display = "flex";
    }
  }
}
